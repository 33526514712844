import * as React from "react"
import './Mutate.scss';
import DiscordButton from '../../components/lib/DiscordButton/DiscordButton';
import Preloader from '../../components/Preloader/Preloader';
import Header from '../../components/HeaderWallet/HeaderWallet';
import { useWeb3Context } from '../../context'
import { useNavigate } from "react-router-dom";
import { ethers } from 'ethers'
import axios from "axios";
import { toast } from 'react-toastify';
import gen2 from '../../assets/images/gen2.png'
import kong1 from '../../assets/images/kong1.jpg'
import kong2 from '../../assets/images/kong2.jpg'
import kong3 from '../../assets/images/kong3.jpg'
import kong4 from '../../assets/images/kong4.jpg'
import kong5 from '../../assets/images/kong5.jpg'
import kong6 from '../../assets/images/kong6.jpg'

const mutantbananaAbi = require('../../helper/mutantBanana.json')
const whitelist = require('../../helper/whitelist.json')
const bananaAbi = require('../../helper/bananaAbi.json')
const stakeAbi = require('../../helper/stakeAbi.json')
const spkAbi = require('../../helper/spk.json')

function Mint() {
    let navigate = useNavigate()


    const bananaContract = "0xF59aFe732B2793d541780fd02945228db1fAd8ed";
    const mutantBananaContract = "0x8ACe1Ec951Bc29b98440d1bbCa64E6010c9E9E17";
    const kongContract = "0x12787526c03d626AAc88E6EDc4d0Fb930d86C631";
    const stakingContract = "0x70C7af7a78B5453E4A09376eB2b506CE4E4140E5";
    const gen2Contract = "0x54Fec6309b53A31e65593f196b4c58f7A704A361"

    const base_uri = "https://api.supremekong.com";

    const { web3Provider, connect, disconnect, address } = useWeb3Context()
    //let address = "0x72e7222Ba5F3B680d7533f1989512e808b7C1ECa"

    const [loaded, setLoaded] = React.useState(false)

    const [mutantAvailable, setMutantAvailable] = React.useState(0)

    const [whitelisted, setWhitelisted] = React.useState(false)

    const [maxAmount, setMaxAmount] = React.useState(5)

    const [amount, setAmount] = React.useState(0);

    const [maxMutantAmount, setMutantMaxAmount] = React.useState(1)

    const [mutantAmount, setMutantAmount] = React.useState(0);

    const [approvedBanana, setApprovedBanana] = React.useState(false)

    const [mintVisible, setMintVisible] = React.useState(true);

    const [mintSelect1, setMintSelect1] = React.useState("switch-selected");

    const [mintSelect2, setMintSelect2] = React.useState("switch");

    const [mintType1, setMintType1] = React.useState(0);

    const [mintType2, setMintType2] = React.useState(0);

    const [openTab, setOpenTab] = React.useState(1);

    const [mintMutantSelect1, setMintMutantSelect1] = React.useState("switch-selected");

    const [mintMutantSelect2, setMintMutantSelect2] = React.useState("switch");

    const countDownDate = new Date("2022-10-01T13:00:00.000-04:00").getTime();
    const [showModal, setShowModal] = React.useState(true);

    const [countDown, setCountDown] = React.useState(
        countDownDate - new Date().getTime()
    );

    const [freeAvailable, setFreeAvailable] = React.useState(0)

    const [bananaNft, setbananaNft] = React.useState([])

    const [day,setDay] = React.useState(0)
    const [hour,setHour] = React.useState(0)
    const [minute,setMinute] = React.useState(0)
    const [second,setSecond] = React.useState(0)
    const [count, setCount] = React.useState(0)

    React.useEffect(() => {
        const interval = setInterval(() => {
          setCountDown(countDownDate - new Date().getTime());
        }, 1000);
    
        const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((countDown % (1000 * 60)) / 1000);
    
        setDay(days)
        setHour(hours)
        setMinute(minutes)
        setSecond(seconds)
        //reduxStore.dispatch(mintEnabled(true))
        if(days + hours + minutes + seconds <= 0){
          setShowModal(false);
          setMintVisible(true);
        }
        return () => clearInterval(interval);
      }, [countDown]);

    const handleLoad = React.useMemo(() => {
        setLoaded(true)
    }, []);

    React.useEffect(async() => {
        //Runs only on the first render
        if(web3Provider){
            //loadBananaNft();

        }else{
        }
    }, [web3Provider])
    

    const loadMutantAvailable = async() => {
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(gen2Contract, spkAbi, signer);
      const mutantContracts = new ethers.Contract(mutantBananaContract, bananaAbi, signer);
      const mutantSupply = await mutantContracts.balanceOf(address)

      const stakingContracts = new ethers.Contract(stakingContract, stakeAbi, signer);

      const stakingMutant = await stakingContracts.stakedNfts(address, 2)

      let mutantArr = Array()

      for (let i = 0; i < mutantSupply.toNumber(); i++) {
        let tokenId = await mutantContracts.tokenOfOwnerByIndex(address, i);
        mutantArr.push(tokenId.toNumber())
      }


      for(let i = 0; i < stakingMutant.length; i++){
        mutantArr.push(stakingMutant[i].toNumber());
      }
      console.log(mutantArr)
      let maxAmtMutant = 0;

      for(let i = 0; i < mutantArr.length;i++){
        let mutantId = mutantArr[i];
        let boolBanana = await contract._paidMutantBananaIds(mutantId)
        if(boolBanana == false){
          maxAmtMutant++;
        }
      }

      console.log(maxAmtMutant)

      setMutantAvailable(maxAmtMutant);
      setMutantMaxAmount(maxAmtMutant)

    }

    const loadKongAvailable = async() => {
      const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(gen2Contract, spkAbi, signer);
      const kongContracts = new ethers.Contract(kongContract, bananaAbi, signer);
      const kongSupply = await kongContracts.balanceOf(address)

      const bananaContracts = new ethers.Contract(bananaContract, bananaAbi, signer);
      const bananaSupply = await bananaContracts.balanceOf(address)

      const mutantContracts = new ethers.Contract(mutantBananaContract, bananaAbi, signer);
      const mutantSupply = await mutantContracts.balanceOf(address)

      const stakingContracts = new ethers.Contract(stakingContract, stakeAbi, signer);
      const stakingKong = await stakingContracts.stakedNfts(address, 0)
      const stakingBanana = await stakingContracts.stakedNfts(address, 1)
      const stakingMutant = await stakingContracts.stakedNfts(address, 2)

      let kongArr = Array()
      let bananaArr = Array()
      let mutantArr = Array()

      
      for (let i = 0; i < kongSupply.toNumber(); i++) {
          let tokenId = await kongContracts.tokenOfOwnerByIndex(address, i);
          kongArr.push(tokenId.toNumber())
      }

      for(let i = 0; i < stakingKong.length; i++){
        kongArr.push(stakingKong[i].toNumber());
      }

      for (let i = 0; i < bananaSupply.toNumber(); i++) {
        let tokenId = await bananaContracts.tokenOfOwnerByIndex(address, i);
        bananaArr.push(tokenId.toNumber())
      }

      for(let i = 0; i < stakingBanana.length; i++){
        bananaArr.push(stakingBanana[i].toNumber());
      }

      for (let i = 0; i < mutantSupply.toNumber(); i++) {
        let tokenId = await mutantContracts.tokenOfOwnerByIndex(address, i);
        mutantArr.push(tokenId.toNumber())
      }

      for(let i = 0; i < stakingMutant.length; i++){
        mutantArr.push(stakingMutant[i].toNumber());
      }

      
      let mintAmt = 0;
      console.log("kong",kongArr.length)
      console.log("mutant",mutantArr.length)
      console.log("banana",bananaArr.length)
      for (let i = 0; i < kongArr.length; i++) {
          let kongId = kongArr[i];
          let boolKong = await contract._redeemedKongIds(kongId)
          if(boolKong == true){
            continue;
          }
          for (let j = 0; j < bananaArr.length; j++) {
              let bananaId = bananaArr[j];
              let boolBanana = await contract._redeemedBananaIds(bananaId)
              if(boolBanana == true){
                continue;
              }
              if (!boolKong && !boolBanana) {
                mintAmt++;
                boolKong = true;
                //console.log(mintAmt)
              }
          }
          for (let j = 0; j < mutantArr.length; j++) {
              let mutantId = mutantArr[j];
              let boolBanana = await contract._redeemedMutantBananaIds(mutantId)
              if(boolBanana == true){
                continue;
              }
              
              if (!boolKong && !boolBanana) {
                  mintAmt++;
                  boolKong = true;
              }
          }

      }
      console.log("mint",mintAmt)
      setFreeAvailable(mintAmt)
  }

    const checkWhitelist = async() => {
      whitelist.map(
        (item) =>{
          if(item.address.toLowerCase() == address.toLowerCase()){
            setWhitelisted(true)
          } 
        }
      )

    }

    const mintSelected1 = () => {
      setMintSelect1("switch-selected")
      setMintSelect2("switch")
      setMintType1(0)
    }

    const mintSelected2 = () => {
      setMintSelect2("switch-selected")
      setMintSelect1("switch")
      setMintType1(1)
    }

    const mintSelected3 = () => {
      setMintMutantSelect1("switch-selected")
      setMintMutantSelect2("switch")
      setMintType2(0)
    }

    const mintSelected4 = () => {
      setMintMutantSelect2("switch-selected")
      setMintMutantSelect1("switch")
      setMintType2(1)
    }


    const increment = () => {
        if(amount < maxAmount){
            setAmount(amount+1);
        }
    }

    const decrement = () => {
        if(amount > 0){
            setAmount(amount-1);
        }
    }

    const mutantIncrement = () => {
      if(mutantAmount < maxMutantAmount){
          setMutantAmount(mutantAmount+1);
      }
  }

  const mutantDecrement = () => {
      if(mutantAmount > 0){
        setMutantAmount(mutantAmount-1);
      }
  }

  const selectTab = (tab) => {
      setOpenTab(tab)
  }

  React.useEffect(async() => {
    //Runs only on the first render
    if(web3Provider){
      loadMutantAvailable()
      loadKongAvailable()
      checkWhitelist()
    }

    if(web3Provider == null){
        setTimeout( () => {
            setCount(count+1);
        },200)
    }

    if(count >= 14){
        navigate('/connect')
    }
}, [web3Provider,count])

    const mutate = async() => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(mutantBananaContract, mutantbananaAbi, signer);
        try{
            const tx = await contract.mutate(address)

            let receipt = await toast.promise(
            tx.wait(),
            {
                pending: 'Mutation in Progress',
                success: 'Mutation Success',
                error: 'Mutation Failed'
            })

            //loadBananaNft()
            toast.success("Mutation Success")
        }catch(err){
            console.log(err)
            toast.success("Mutation Failed")
        }
    }

    const bananaApproveAll = async() => {
        const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(bananaContract, bananaAbi, signer);
        try{
            const tx = await contract.setApprovalForAll(mutantBananaContract, true)
            let receipt = await toast.promise(
                tx.wait(),
                {
                    pending: 'Validation in Progress',
                    success: 'Validation Success',
                    error: 'Validation Failed'
                })
            const approval = await contract.isApprovedForAll(address,mutantBananaContract)

            setApprovedBanana(approval)
        }catch(err){
            console.log(err)
            toast.success("Approval Failed")
        }
    }

    const publicMint = async() => {
        if(web3Provider){
        const signer = web3Provider?.getSigner();
          const contract = new ethers.Contract(gen2Contract, spkAbi, signer);
          let price = ethers.utils.parseEther("0.099");
          price = price.mul(amount);
          try{
            let overrides = {
              value: price
            };
            const tx = await contract.publicMint(address,amount,overrides)
            let receipt = await tx.wait()
            toast.success("Minting Success")
          } catch(err){
            console.log(err)
            toast.success("Minting Failed")
          }
        }else{
          toast.error("Please Connect Your Wallet using Metamask")
        }
    }

    const publicMintandStake = async() => {
      if(web3Provider){
      const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(gen2Contract, spkAbi, signer);
        let price = ethers.utils.parseEther("0.08");
        price = price.mul(amount);
        try{
          let overrides = {
            value: price
          };
          const tx = await contract.publicMintAndStake(address,amount,overrides)
          let receipt = await tx.wait()
          toast.success("Minting Success")
        } catch(err){
          console.log(err)
          toast.success("Minting Failed")
        }
      }else{
        toast.error("Please Connect Your Wallet using Metamask")
      }
    }


      const mutantMint = async() => {
        if(web3Provider){
        const signer = web3Provider?.getSigner();
          const contract = new ethers.Contract(gen2Contract, spkAbi, signer);
          let price = ethers.utils.parseEther("0.099");
          price = price.mul(mutantAmount);
          try{
            let overrides = {
              value: price
            };
            const tx = await contract.mutantMint(address,mutantAmount,overrides)
            let receipt = await tx.wait()
            toast.success("Minting Success")
          } catch(err){
            console.log(err)
            toast.success("Minting Failed")
          }
        }else{
          toast.error("Please Connect Your Wallet using Metamask")
        }
    }

    const mutantMintAndStake = async() => {
      if(web3Provider){
      const signer = web3Provider?.getSigner();
        const contract = new ethers.Contract(gen2Contract, spkAbi, signer);
        let price = ethers.utils.parseEther("0.08");
        price = price.mul(mutantAmount);
        try{
          let overrides = {
            value: price
          };
          const tx = await contract.mutantMintAndStake(address,mutantAmount,overrides)
          let receipt = await tx.wait()
          toast.success("Minting Success")
        } catch(err){
          console.log(err)
          toast.success("Minting Failed")
        }
      }else{
        toast.error("Please Connect Your Wallet using Metamask")
      }
  }

  const whitelistMint = async() => {
    if(web3Provider){
      const resp = await axios.post(base_uri+'/signWhitelist',
        {
            address: address,
            amount: amount
        },
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })
        console.log(resp.data)


    const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(gen2Contract, spkAbi, signer);
      let price = ethers.utils.parseEther("0.099");
      price = price.mul(amount);
      try{
        let overrides = {
          value: price
        };
        const tx = await contract.whitelistMint(address,amount,resp.data.nonce,resp.data.hash,resp.data.signature,overrides)
        let receipt = await tx.wait()
        toast.success("Minting Success")
      } catch(err){
        console.log(err)
        toast.success("Minting Failed")
      }
    }else{
      toast.error("Please Connect Your Wallet using Metamask")
    }
  }

  const whitelistMintStake = async() => {
    if(web3Provider){
      const resp = await axios.post(base_uri+'/signWhitelist',
        {
            address: address,
            amount: amount
        },
        {
        headers: {
            api_key: 'R8NQzvVkmt5g2n5BNr6kkfEhD8Jm6KLzLPf5lukrz7zMJjIPb9BDhbpQPOQ4nB4m' //the token is a variable which holds the token
        }
        })

    const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(gen2Contract, spkAbi, signer);
      let price = ethers.utils.parseEther("0.080");
      price = price.mul(amount);
      try{
        let overrides = {
          value: price
        };
        const tx = await contract.whitelistMintAndStake(address,amount,resp.data.nonce,resp.data.hash,resp.data.signature,overrides)
        let receipt = await tx.wait()
        toast.success("Minting Success")
      } catch(err){
        console.log(err)
        toast.success("Minting Failed")
      }
    }else{
      toast.error("Please Connect Your Wallet using Metamask")
    }
  }

  const freeMint = async() => {
    if(web3Provider){
    const signer = web3Provider?.getSigner();
      const contract = new ethers.Contract(gen2Contract, spkAbi, signer);
      try{
        const tx = await contract.freeMint(address)
        let receipt = await tx.wait()
        toast.success("Minting Success")
      } catch(err){
        console.log(err)
        toast.success("Minting Failed")
      }
    }else{
      toast.error("Please Connect Your Wallet using Metamask")
    }
  }

  const paidMint = async() =>{
    if(mintType1 == 0){
      if(whitelisted == true){
        whitelistMintStake()
        return
      }
      publicMintandStake()
    }else{
      if(whitelisted == true){
        whitelistMint()
        return
      }
      publicMint()
    }
  }

  const paidMutantMint = async() =>{
    if(mintType2 == 0){
      mutantMintAndStake()
    }else{
      mutantMint()
    }
  }

    return (
      //   <>
      //   <Preloader handleLoad={handleLoad} />
      //   <Header siteTitle={`Title`} />
      //   <div className='Mint__content'>
      //   {/* <motion.h1 
      //   {...paragraphAnimation}
      //   className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
      //   <div className='flex-container'>
      //   { mintVisible &&
      //   <>
      //     <div className='flex-item-right' style={{color:'white'}}>

      //       <div className='address' style={{marginBottom:"20px",color:'#f2b90c'}}>
      //         <p style={{fontSize:"64px",fontWeight:"800"}}></p>
      //       </div>

      //       <div className='address'>
      //         <p style={{fontSize:"18px",fontWeight:"800",color:'#f2b90c'}}>Amount</p>
      //       </div>

      //       <div className='amountBtn'>
      //         <div className='decrement' onClick={decrement}>
      //         </div>

      //         <div className='amountTxt'>
      //           {amount}
      //         </div>

      //         <div className='increment' onClick={increment}>
      //         </div>
      //       </div>

      //       <div className='mintBtn' onClick={publicMint}>
      //           Public Mint
      //       </div>

      //       <div className='mintBtn' onClick={publicMintStake}>
      //           Public Mint and Stake
      //       </div>

      //       <div className='mintBtn' onClick={freeMint}>
      //           Free Mint
      //       </div>

      //       {/* <div className='mintBtn' onClick={mutantMint}>
      //           Mutant Mint
      //       </div> */}
          
      //     </div>
      //     </>
      //     }
      //     { showModal &&
      //       <div className='modal-overlay'>
      //               <div className='modal-title'>
      //               <div className='modal-title-txt' >Countdown To Mint</div>
      //               </div>
      //               <div className='modal-timer'>
      //               <div className='modal-timer-txt'>{day}:{hour}:{minute}.{second}</div>
      //               </div>
      //       </div>
      //     }
      //   </div>
      // </div>
      //   </>

      <>
      <Preloader handleLoad={handleLoad} />
      <Header siteTitle={`Title`} />
      <div
      id='Mint' className='Mint'>
        { mintVisible &&
        <div className='Mint__content'>
          {/* <motion.h1 
          {...paragraphAnimation}
          className='Mint__content__title Title'>THE SUPREME LEADERS OF THE METAVERSE</motion.h1> */}
          <div className='flex-container'>
            <div className='thumbnails flex-item-left'>
            <ul className="thumbnails-left">
                <li>
                  <a href="#slide1"><img src={kong1} /></a>
                </li>
                <li>
                  <a href="#slide2"><img src={kong2} /></a>
                </li>
                <li>
                  <a href="#slide3"><img src={kong4} /></a>
                </li>
                <li>
                  <a href="#slide4"><img src={kong3} /></a>
                </li>
              </ul>
              <ul className="thumbnails-mid">
                <li>
                  <a href="#slide1"><img src={kong5} /></a>
                </li>
                <li>
                  <a href="#slide2"><img src={gen2} /></a>
                </li>
                <li>
                  <a href="#slide3"><img src={kong1} /></a>
                </li>
                <li>
                  <a href="#slide4"><img src={kong4} /></a>
                </li>
              </ul>
              <ul className="thumbnails-right">
                <li>
                  <a href="#slide1"><img src={kong6} /></a>
                </li>
                <li>
                  <a href="#slide2"><img src={kong2} /></a>
                </li>
                <li>
                  <a href="#slide3"><img src={kong5} /></a>
                </li>
                <li>
                  <a href="#slide4"><img src={kong3} /></a>
                </li>
              </ul>
            </div>
            <div className='flex-item-right' style={{color:'white'}}>
          
            

            <div className="box-mint" onClick={() =>{ setOpenTab(3) }}>
              <>
              <div className='address'>
                <p style={{fontSize:"18px",fontWeight:"800", paddingTop:"10px"}}>Supreme Kong 2 SOLD OUT</p>
              </div>
              

              <div className='mintBtn' >
                <a href="https://opensea.io/collection/supreme-kong-2-v2">
                  Go To OpenSea
                </a>
              </div>
              </> 
            </div>

            <div className="box-mint" onClick={() =>{ setOpenTab(3) }}>
              <>
              <div className='address'>
                <p style={{fontSize:"18px",fontWeight:"800", paddingTop:"10px"}}>Supreme Kong</p>
              </div>
              

              <div className='mintBtn' >
                <a href="https://opensea.io/collection/supremekong">
                  Go To OpenSea
                </a>
              </div>
              </> 
            </div>

            <div className="box-mint" onClick={() =>{ setOpenTab(3) }}>
              <>
              <div className='address'>
                <p style={{fontSize:"18px",fontWeight:"800", paddingTop:"10px"}}>Supreme Banana</p>
              </div>
              

              <div className='mintBtn' >
                <a href="https://opensea.io/collection/supremebananas">
                  Go To OpenSea
                </a>
              </div>
              </> 
            </div>

            <div className="box-mint" onClick={() =>{ setOpenTab(3) }}>
              <>
              <div className='address'>
                <p style={{fontSize:"18px",fontWeight:"800", paddingTop:"10px"}}>Supreme Mutant Banana</p>
              </div>
              

              <div className='mintBtn' >
                <a href="https://opensea.io/collection/supreme-mutant-bananas">
                  Go To OpenSea
                </a>
              </div>
              </> 
            </div>

            </div>
          </div>
        </div>
        }
      </div>
      { showModal &&
        <div className='modal-overlay'>
              <div className='modal-title'>
                <a href='https://opensea.io/collection/supremebananas'>
                  <DiscordButton onClick={ ()=>{}} href='' text='BUY ON OPENSEA' blank />
                </a>
              </div>
              
        </div>
        
      }
    </>
    );
}

export default Mint;
